<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <div class="form">
      <div class="card card-custom">
        <div class="card-header border-0 pt-6 pb-0">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder">
              {{ $t("Nation Price List") }}
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1">
              {{ $t("Change nation's price list") }}
            </span>
          </div>
          <div class="card-toolbar">
            <button class="btn btn-success mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click.prevent="handleSubmit(save)">
              {{ $t("Save Changes") }}
            </button>
          </div>

        </div>
        <div class="card-body detail">
          <validation-provider v-slot="{ errors }" :name="$t('Service')" vid="service_available" rules="required"
            tag="div">
            <euro-radio v-model="form.risk_maps.service_available" :options="OPTIONS"
              :label="`${$t('Service Available')}`" horizontal :error-messages="errors" size="lg"></euro-radio>
          </validation-provider>
          <validation-provider v-slot="{ errors }" :name="$t('Geopolitical report price multiplier')" vid="geopolitical_price"
            rules="max:30|required">
            <euro-input v-model="form.risk_maps.geopolitical_report_price_multiplier"
              :label="`${$t('Geopolitical report price multiplier')}`" horizontal :error-messages="errors"></euro-input>
          </validation-provider>
          <validation-provider v-slot="{ errors }" :name="$t('Risk Map Price multiplier')" vid="price_multiplier"
            rules="max:30|required">
            <euro-input v-model="form.risk_maps.price_multiplier" :label="`${$t('Risk Map Price multiplier')}`" horizontal
              :error-messages="errors"></euro-input>
          </validation-provider>

        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import NationsCountryService from "@/core/services/nation/nations-country.service";
import { getValidationErrors, successToast } from "@/core/helpers";
import { backendErrorSwal, unsavedChangesSwal } from "@/core/helpers/swal";
import _ from "lodash";
export default {
  props: {
    nation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      OPTIONS: [],
      isSaving: false,
      form: {
        risk_maps: {
          geopolitical_report_price_multiplier: "",
          price_multiplier: "",
          service_available: null,
        }
      },
      originalData: null,
    }
  },
  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;

        const newErrors = getValidationErrors(val, this.legalPersonForm);

        formObs.setErrors({ ...newErrors });
      },
    },
  },
  async created() {
    this.fillForm();
    this.OPTIONS = [{
      value: true,
      text: "Yes"
    },
    {
      value: false,
      text: "No"
    }]
  },
  methods: {
    fillForm() {
      this.form = {
        risk_maps: {
          geopolitical_report_price_multiplier: this.nation.risk_maps.geopolitical_report_price_multiplier,
          price_multiplier: this.nation.risk_maps.price_multiplier,
          service_available: this.nation.risk_maps.service_available,
        }
      }
      this.originalData = _.cloneDeep(this.form);
    },
    async save() {
      this.isSaving = true;
      const body = { ...this.form, };
      try {
        await NationsCountryService.update(this.nation.iso, body);
        this.originalData = _.cloneDeep(this.form);
        successToast(this.$t("Nation price list saved"));
        this.$emit("updated");
      } catch (err) {
        console.log(err?.response?.status);
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err, err?.response?.data?.detail ?? this.$t("Failed to save nation price list"));
        }
      } finally {
        this.isSaving = false;
      }
    },
    cancel() {
      this.fillForm();
    },
    async beforeRouteLeave(__, ___, next) {
      if (!_.isEqual(this.form, this.originalData)) {
        const res = await unsavedChangesSwal();
        if (res.isConfirmed) await this.save();
        if (res.isDismissed) return;
      }
      next();
    },
  }

};
</script>